<template>
    <div>

        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      
        <br />
          <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
            <v-card width="400" >
              <center class="pa-5">
               Importing Book data.<br />
                Please Wait It will Take Time..
                
              </center>
              <div class="ma-6">
              <v-progress-linear 
      v-model="percent"
      color="amber"
      height="25"
      
    >
     <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
      </v-progress-linear>
              </div>
            </v-card>
            </v-row>
          </v-dialog>
        <v-card class="card">
            <v-card-title class="heading justify-center">Import Books</v-card-title>
            <v-card-text>
                <div class="add-section">
                    <v-row class="justify-content-between set-btn">
                        <div><a href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/library_import_book_csv_new.xlsx">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export XLSX Template</v-btn></a>
                        </div>
                        <!-- <div>
                            <v-btn class="mb-2 button" @click="addOrg">Add Library</v-btn>
                        </div> -->
                    </v-row>
                    <p class="file-warn">NOTE: *File should be in XLSX format.</p>

                    <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">IMPORT XLSX</label>
                            <v-file-input
                                dense
                                v-model="file"
                                :rules="[v => !!v || 'File required']"
                                persistent-hint
                                color="rgb(1, 127, 152)"
                                label="Choose File"
                                
                                prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus?'File Required':''"
                                outlined
                                show-size
                            >
                            </v-file-input>
                            <v-btn :loading="output_load" @click="importCsv" class="button">
                                Import
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <template>
                    <h5 v-if="failList !== null" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-simple-table v-if="failList !== null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>Error</th>
                                    <th>Accession No.</th>
                                    <th>ISBN</th>
                                    <th>Series</th>
                                    <th>Title - Subtitle</th>
                                    <th>author</th>
                                    <th>Edition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name" style="color:red;">
                                    <th>{{item.rowno}} </th>
                                    <th>{{item.error}}  </th>
                                    <th>{{item.row.accession_no}} </th>
                                    <th>{{item.row.isbn}} </th>
                                    <th>{{item.row.series}} </th>
                                    <th>{{item.row.title_subtitle}} </th>
                                    <th>{{item.row.author}} </th>
                                    <th>{{item.row.edition}} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <template>
                    <h5 v-if="successList !== null" class="mt-4" style="text-align: center; color: green;">Success Record</h5>
                    <v-simple-table v-if="successList !== null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Excel row no</th>
                                    <th>Accession No.</th>
                                    <th>ISBN</th>
                                    <th>Series</th>
                                    <th>Title - Subtitle</th>
                                    <th>author</th>
                                    <th>Edition</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in successList" :key="item.name" style="color:green;">
                                    <th>{{item.rowno}} </th>
                                    <th>{{item.row.accession_no}} </th>
                                    <th>{{item.row.isbn}} </th>
                                    <th>{{item.row.series}} </th>
                                    <th>{{item.row.title_subtitle}} </th>
                                    <th>{{item.row.author}} </th>
                                    <th>{{item.row.edition}} </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>


            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    
   
    export default {
        data: () => ({
            percent: 0,
            valid: false,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            isLoading:false,
            failList: [],
            successList : [],
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            org: "",
            file: null,
            communicationMethod: [],
            inSave: true,
            dialog: false,
           
            bookCategoryList: [],
            notifyTypes: [],
            fileStatus: true,
            lib_list: [],
            role_list: [],
            editedIndex: -1,
            editedItem: {
                name: "",
                address: "",
                email: "",
                contact: "",
                country: "",
                pin: "",
                city: "",
                state: "",
                isactive: false,
                id: "",
            },
            defaultItem: {
                name: "",
                address: "",
                email: "",
                contact: "",
                country: "",
                pin: "",
                city: "",
                state: "",
                isactive: false,
                id: "",
            },
            count:-1,
            totalbook:0,
            totalbooksize:0,
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Library" : "Edit Library";
            },
        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },
        mounted() {
            // this.onLoad();
        },
        methods: {
            fileuploading(){
                let formData = new FormData();
                this.fileStatus = true;
                        formData.append("file", this.file);
                        formData.append("count", this.count);
                        axios
                            .post("ImportData/importBook", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                              
                                // this.failList = res.data.failList;
                                // this.successList = res.data.successList;
                                if (res.data.status == "200") {
                                    if(this.count!=-1){
                                        
                                    for(var i=0;i<res.data.successList.length;i++){
                                        this.successList.push( res.data.successList[i]);
                                    }
                                    
                                    for(var i=0;i<res.data.failList.length;i++){
                                         this.failList.push(res.data.failList[i]);
                                    }
                                    }
                                  
                                    this.count=res.data.count;
                                    this.totalbooksize=res.data.totalbooksize;
                                    this.percent=(this.count/this.totalbooksize)*100;
                                    if(res.data.isdone)
                                    {this.count=-1;
                                        this.isLoading=false;
                                            this.showSnackbar("#4caf50", "Books added successfully...");
                                    }
                                    else{
                                        this.fileuploading();
                                    }
                                    // this.onLoad();
                                }
                                
                            })
                           
                           
            },
            importCsv() {
                this.isLoading=true;
                this.failList=[];
                this.successList=[];
                if (this.file !== null) {
                   if (!(this.file.name.toLowerCase().includes(".xlsx") || this.file.name.toLowerCase().includes(".XLSX"))) {
                       this.showSnackbar("red", "File must be in xlsx format Only.!");
                   } else{
                        this.fileuploading();
                    }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
            },
            addOrg() {
                this.inSave = true;
                this.dialog = true;
                this.$refs.form.reset();
            },
            editItem(item) {
                this.inSave = false;
                this.editedIndex = this.lib_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
                var name = item.role;
                for (let x in this.role_list) {
                    if (this.role_list[x].name == name) {
                        this.editedItem.role = this.role_list[x].name;
                        this.editedItem.roleId = this.role_list[x].id;
                        break;
                    }
                }
            },
            onLoad() {
                this.overlay = true;
                axios
                    .post("/Admin/getLibraryData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.lib_list = res.data.lib_list;
                            this.communicationMethod = res.data.communicationMethod;
                            this.notifyTypes = res.data.notifyTypes;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                if (this.editedIndex > -1) {
                    if (this.$refs.form.validate()) {
                        axios
                            .post("/Admin/editLibrary", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    //window.alert(res.data.msg)
                                    Object.assign(this.lib_list[this.editedIndex], this.editedItem);
                                    this.showSnackbar("#4caf50", "Library Updated Successfully..."); // show snackbar on success
                                    // this.onLoad();
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", "Library Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                        this.close();
                    }
                } else {
                    // this.lib_list.push(this.editedItem)
                    axios
                        .post("/Admin/saveLibrary", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                this.showSnackbar("#4caf50", "Library Added Successfully!!!"); // show snackbar on success
                                // this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Library Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }, //save()

            activeOn(item) {
                console.log(item);
                axios
                    .post("/admin/libraryIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Library updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .add-section {
        display: block;
    }
    .import-box {
        margin: 1rem 1rem 2rem 1rem;
        border: 2px solid;
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
        background: #ffffffb5;
    }
    .label {
        color: black;
        font-size: 16px;
        font-weight: 400;
    }
    .button {
        background: white !important;
    }
    .set-btn {
        margin: 0rem 1rem 0rem 1rem;
        padding-top: 1rem;
    }
    .button:hover {
        color: white;
        background: black !important;
    }
    .file-warn {
        font-style: italic;
        color: red;
        text-align: center;
        margin: 0;
    }
</style>
